<template>
     <v-card  class="contactDetailContent">

        <v-card-text class="pa-4">
            <v-tabs v-model="tabId">
                <v-tab class="tabHead primary--text" :key="'demands'" v-if="$func.hasRight('lead/get')">
                    <v-icon small color="primary" class="mr-2">$spinner_d</v-icon>
                    Demandes
                </v-tab>
                <v-tab class="tabHead primary--text" :key="'products'" v-if="packId != 1 && packId != 4">
                    <v-icon small color="primary" class="mr-2">$shoppingbasket_d</v-icon>
                    Produits
                </v-tab>
                <v-tab class="tabHead primary--text" :key="'activity'">
                    <v-icon small color="primary" class="mr-2">$chartnetwork_d</v-icon>
                    Activité
                </v-tab>
                <v-tab class="tabHead primary--text" :key="'statistics'" v-if="packId != 4">
                    <v-icon small color="primary" class="mr-2">$analytics_d</v-icon>
                    Statistiques
                </v-tab>
                <v-tab class="tabHead primary--text" v-if="upsell">
                    <v-img :height="20" :width="20" class="mr-2" :src="upsell.picto.url"></v-img>
                    {{$t('upsell')}}
                </v-tab>
                <v-tab class="tabHead primary--text" v-if="$func.hasRight('exchange/get')">
                    <v-icon small color="primary" class="mr-2">$mailbox_flag_up_d</v-icon>
                    {{ $t('exchanges') }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabId">
                <v-tab-item  class="pa-4" v-if="$func.hasRight('lead/get')">
                    <demandsTabComponent ref='demandsTabComponent' v-on="$listeners"/>
                </v-tab-item>
                <v-tab-item v-if="packId != 1 && packId != 4">
                    <productTabComponent />
                </v-tab-item>
                <v-tab-item>
                    <activityTabComponent @gotToLink="goToLink" />
                </v-tab-item>
                <v-tab-item v-if="packId != 4">
                    <statsTabComponent />
                </v-tab-item>
                <v-tab-item v-if="upsell">
                    <opportunityTabComponent @refreshOpportunity="refreshOpportunity" :opportunity="upsell" :key="'upsell_'+upsellKey"/>
                </v-tab-item>
                <v-tab-item v-if="$func.hasRight('exchange/get')">
                    <exchangesTabComponent />
                </v-tab-item>
            </v-tabs-items>    
        </v-card-text>
    </v-card>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import demandsTabComponent from '@/modules/contacts/contactsComponents/demandsTabComponent'
import productTabComponent from './productTabComponent.vue'
import statsTabComponent from './statsTabComponent.vue'
import exchangesTabComponent from './exchangesTabComponent.vue'
import opportunityTabComponent from '@/modules/contacts/contactsComponents/opportunityTabComponent'
import activityTabComponent from './activityTabComponent.vue'
export default {
    name: "contentContactComponent",
    components: {demandsTabComponent, productTabComponent, statsTabComponent, exchangesTabComponent, activityTabComponent,opportunityTabComponent},
    data() {
        return {
            tab:0,
            upsell: false,
            upsellKey:0
        }
    },
    mounted() {
        if(this.$route.params.contact_id){
            GenericDataService.getData('/contact/'+this.$route.params.contact_id+'/getAlerts').then((response) => {
                if(response && response.data && response.data.data)
                    this.upsell = response.data.data.filter((alert) => {
                        return alert.tag == 'upsell'
                    })[0];
            })
        }
    },
    watch: {
        $route(val){
            if(val && val.id){
                GenericDataService.getData('/contact/'+this.$route.params.contact_id+'/getAlerts').then((response) => {
                    this.upsell = response.data.data.filter((alert) => {
                        return alert.tag == 'upsell'
                    })[0];
                })
            }
        }
    },
    computed: {
        tabId: {
            get: function(){
                return this.$store.state.contact.tabId;
            },
            set: function(val){
                this.$store.dispatch("contact/SET_TAB_ID", val);
            }
        },
        packId(){
            if(this.$store.state.auth.currentUser.accountParameters.packId)
                return this.$store.state.auth.currentUser.accountParameters.packId
            else 
                return false
        },
    },
    methods: {
        goToLink(link, category){
            if(link != this.$route.fullPath){
                this.tabId = 0;
                this.$router.push(link)
            } else {
                this.tabId = 0
            }
        },
        refreshOpportunity(){
            GenericDataService.getData('/contact/'+this.$route.params.contact_id+'/getAlerts').then((response) => {
                this.upsell = response.data.data.filter((alert) => {
                    return alert.tag == 'upsell'
                })[0];

                this.upsellKey++
            })
        },
        refreshInteractions(){
            this.$refs.demandsTabComponent.refreshInteractions()
        }
    },
}
</script>
<style lang="scss">
    .contactDetailContent {
        .tabHead {
            text-transform: none;
        }
    }
</style>