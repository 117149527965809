<template>
    <v-card class="historyComponent no-shadow">
        <v-card-title>
            <h2 class="mb-0">{{$t('demandHistory')}}</h2>
        </v-card-title>
        <v-card-text class="pr-4">
            <div style="position:relative;  height:300px; overflow:hidden; overflow-y:auto;" class="newScroll">
                <div style="position:absolute; width:100%;">
                    <v-timeline dense>
                        <v-timeline-item
                            v-for="(item, index) in history"
                            right
                            color="white"
                            small
                            :key="'history_'+index"
                        >
                        <template v-slot:icon>
                            <v-avatar max-height="40" max-width="40"  min-height="40" min-width="40" class="avatarHistory">
                                <img :src="api+'/user/getAvatar?id=' + (item.real_user.id != item.user.id ? item.real_user.id : item.user.id) + '&token='+$store.state.auth.idToken">
                            </v-avatar>
                        </template>
                        <v-expansion-panels>
                            <v-expansion-panel
                                active-class="active"
                            >
                            <v-expansion-panel-header class="pa-0" color="extralightgrey" hide-actions>
                                <div class="d-flex flex-column">
                                    {{parseFullDateToFr(item.date)}}
                                    <b>{{$t(item.title)}}</b>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content color="extralightgrey">
                                <v-icon small>$user_tie_l</v-icon>
                                <p class="mb-0 ml-3"><span v-if="item.real_user.id != item.user.id"><b>{{item.real_user.label}}</b> connecté en tant que </span><b>{{item.user.label}}</b></p>
                            </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        </v-timeline-item>
                    </v-timeline>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
export default {
    name:"historyDemandComponent",
    mixins:[dateManipulation],
    props:['history'],
    data() {
        return {
             api:this.$store.state.auth.apiUrl,
        }
    },
}
</script>
<style lang="scss">
    .historyComponent {
        border:1px solid var(--v-lightgrey-base) !important;
        background-color: var(--v-extralightgrey-base) !important;
        border-color: var(--v-lightgrey-base) !important;
        // .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse):before {
        //     left:calc( 25% + 24px);
        // }
        // .v-timeline-item__body {
        //     min-width:calc( 75% - 48px);
        //     max-width:calc( 75% - 48px);
        // }
        // .v-timeline-item__opposite {
        //     flex-grow:0;
        //     width:100px;
        // }
        // .v-timeline-item__divider {
        //     min-width:48px;
        // }
        .v-expansion-panel-content__wrap {
            display:flex;
            align-items:center;
        }
        .v-expansion-panels {
            border-radius:0px !important;
        }
        .v-expansion-panel-header--active, .v-expansion-panel-header:hover {
            color:var(--v-primary-base) !important;
        }
        .avatarHistory {
            border:2px solid white;
            background-color:white;
            -webkit-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
            -moz-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
            box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
        }
        .newScroll {
            &::-webkit-scrollbar-track
            {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                border-radius: 10px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar
            {
                width: 8px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb
            {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: var(--v-purple-base) !important;
            }
        }
    }
</style>